*{
  margin: 0;
  padding: 0;
  text-decoration: none;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-ExtraLight.eot');
  src: url('./fonts/Nunito-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Nunito-ExtraLight.woff2') format('woff2'),
      url('./fonts/Nunito-ExtraLight.woff') format('woff'),
      url('./fonts/Nunito-ExtraLight.ttf') format('truetype'),
      url('./fonts/Nunito-ExtraLight.svg#Nunito-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-ExtraLightItalic.eot');
  src: url('./fonts/Nunito-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Nunito-ExtraLightItalic.woff2') format('woff2'),
      url('./fonts/Nunito-ExtraLightItalic.woff') format('woff'),
      url('./fonts/Nunito-ExtraLightItalic.ttf') format('truetype'),
      url('./fonts/Nunito-ExtraLightItalic.svg#Nunito-ExtraLightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-Italic.eot');
  src: url('./fonts/Nunito-Italic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Nunito-Italic.woff2') format('woff2'),
      url('./fonts/Nunito-Italic.woff') format('woff'),
      url('./fonts/Nunito-Italic.ttf') format('truetype'),
      url('./fonts/Nunito-Italic.svg#Nunito-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-Light.eot');
  src: url('./fonts/Nunito-Light.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Nunito-Light.woff2') format('woff2'),
      url('./fonts/Nunito-Light.woff') format('woff'),
      url('./fonts/Nunito-Light.ttf') format('truetype'),
      url('./fonts/Nunito-Light.svg#Nunito-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-LightItalic.eot');
  src: url('./fonts/Nunito-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Nunito-LightItalic.woff2') format('woff2'),
      url('./fonts/Nunito-LightItalic.woff') format('woff'),
      url('./fonts/Nunito-LightItalic.ttf') format('truetype'),
      url('./fonts/Nunito-LightItalic.svg#Nunito-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-Medium.eot');
  src: url('./fonts/Nunito-Medium.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Nunito-Medium.woff2') format('woff2'),
      url('./fonts/Nunito-Medium.woff') format('woff'),
      url('./fonts/Nunito-Medium.ttf') format('truetype'),
      url('./fonts/Nunito-Medium.svg#Nunito-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-MediumItalic.eot');
  src: url('./fonts/Nunito-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Nunito-MediumItalic.woff2') format('woff2'),
      url('./fonts/Nunito-MediumItalic.woff') format('woff'),
      url('./fonts/Nunito-MediumItalic.ttf') format('truetype'),
      url('./fonts/Nunito-MediumItalic.svg#Nunito-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-Regular.eot');
  src: url('./fonts/Nunito-Regular.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Nunito-Regular.woff2') format('woff2'),
      url('./fonts/Nunito-Regular.woff') format('woff'),
      url('./fonts/Nunito-Regular.ttf') format('truetype'),
      url('./fonts/Nunito-Regular.svg#Nunito-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-SemiBold.eot');
  src: url('./fonts/Nunito-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Nunito-SemiBold.woff2') format('woff2'),
      url('./fonts/Nunito-SemiBold.woff') format('woff'),
      url('./fonts/Nunito-SemiBold.ttf') format('truetype'),
      url('./fonts/Nunito-SemiBold.svg#Nunito-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-SemiBoldItalic.eot');
  src: url('./fonts/Nunito-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Nunito-SemiBoldItalic.woff2') format('woff2'),
      url('./fonts/Nunito-SemiBoldItalic.woff') format('woff'),
      url('./fonts/Nunito-SemiBoldItalic.ttf') format('truetype'),
      url('./fonts/Nunito-SemiBoldItalic.svg#Nunito-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-Black.eot');
  src: url('./fonts/Nunito-Black.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Nunito-Black.woff2') format('woff2'),
      url('./fonts/Nunito-Black.woff') format('woff'),
      url('./fonts/Nunito-Black.ttf') format('truetype'),
      url('./fonts/Nunito-Black.svg#Nunito-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-BlackItalic.eot');
  src: url('./fonts/Nunito-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Nunito-BlackItalic.woff2') format('woff2'),
      url('./fonts/Nunito-BlackItalic.woff') format('woff'),
      url('./fonts/Nunito-BlackItalic.ttf') format('truetype'),
      url('./fonts/Nunito-BlackItalic.svg#Nunito-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-Bold.eot');
  src: url('./fonts/Nunito-Bold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Nunito-Bold.woff2') format('woff2'),
      url('./fonts/Nunito-Bold.woff') format('woff'),
      url('./fonts/Nunito-Bold.ttf') format('truetype'),
      url('./fonts/Nunito-Bold.svg#Nunito-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-BoldItalic.eot');
  src: url('./fonts/Nunito-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Nunito-BoldItalic.woff2') format('woff2'),
      url('./fonts/Nunito-BoldItalic.woff') format('woff'),
      url('./fonts/Nunito-BoldItalic.ttf') format('truetype'),
      url('./fonts/Nunito-BoldItalic.svg#Nunito-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-ExtraBold.eot');
  src: url('./fonts/Nunito-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Nunito-ExtraBold.woff2') format('woff2'),
      url('./fonts/Nunito-ExtraBold.woff') format('woff'),
      url('./fonts/Nunito-ExtraBold.ttf') format('truetype'),
      url('./fonts/Nunito-ExtraBold.svg#Nunito-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-ExtraBoldItalic.eot');
  src: url('./fonts/Nunito-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Nunito-ExtraBoldItalic.woff2') format('woff2'),
      url('./fonts/Nunito-ExtraBoldItalic.woff') format('woff'),
      url('./fonts/Nunito-ExtraBoldItalic.ttf') format('truetype'),
      url('./fonts/Nunito-ExtraBoldItalic.svg#Nunito-ExtraBoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

:root {
  --dark-blue: #040025;
  --light-blue: #2274A5;
  --light-gray: #E9F1F7;
  --light-yellow: #E7DFC6;
  --light-brown: #816C61;
}

html{
  min-height: 100%;
  height: 100%;
}

body{
  background-color: var(--dark-blue);
  background-repeat: repeat;
  color: var(--light-gray);
  min-height: 100%;
  height: 100%;
  font-family: 'Nunito';
  font-size: 1.3vw;
}

#root{
  position: absolute;  
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  margin: 10px;  
}

.wrapper{
  border: 1px solid var(--light-gray);
  box-sizing: border-box;
  padding: 10px;
  min-height: 100%;
  height: 100%;
  background-image: url(./img/background-home.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /*position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-event: none;
  z-index: 300;
  transform: translateZ(0);*/

  &:before {
    content: "";
    top: -10rem;
    left: -10rem;
    width: calc(100% + 20rem);
    height: calc(100% + 20rem);
    z-index: 9999;
    position: fixed;
    background-image: url(./img/gaussian-noise.png);
    opacity: 0.15;
    pointer-events: none;
    -webkit-animation: noise 1s steps(2) infinite;
    animation: noise 1s steps(2) infinite;
  }

  @-webkit-keyframes noise {
    to { transform: translate3d(-7rem,0,0) }
  }

  @keyframes noise {
    0% { transform: translate3d(0,9rem,0) }
    10% { transform: translate3d(-1rem,-4rem,0) }
    20% { transform: translate3d(-8rem,2rem,0) }
    30% { transform: translate3d(9rem,-9rem,0) }
    40% { transform: translate3d(-2rem,7rem,0) }
    50% { transform: translate3d(-9rem,-4rem,0) }
    60% { transform: translate3d(2rem,6rem,0) }
    70% { transform: translate3d(7rem,-8rem,0) }
    80% { transform: translate3d(-9rem,1rem,0) }
    90% { transform: translate3d(6rem,-5rem,0) }
    to { transform: translate3d(-7rem,0,0) }
  }
}

@media(max-width: 767px){
  .wrapper{
    background-image: url(./img/background-home-mobile.jpg);
  }
}

.content{
  overflow: hidden;
  overflow-y: auto;
}

.strong{
  font-weight: 600;
}

.italic{
  font-style: italic;
}

/* width */
::-webkit-scrollbar {
  width: 3px; 
}

/* Track */
::-webkit-scrollbar-track {
  background-color: var(--dark-blue);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--light-blue);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--light-blue);
}

@media(min-width: 768px) and (max-width: 991px){
  body{
    font-size: 1.8vw;
  }
}

@media(max-width: 767px) and (orientation: portrait){
  body{
    font-size: 5vw;
  }
}

@media(max-width: 767px) and (orientation: landscape){
  body{
    font-size: 3vw;
  }
}


/* Header */

.header{
  display: flex;
  flex-wrap: wrap;
  height: 20%;
}

.branding-socials{
  flex-basis: 50%;
}

.branding h1{
  font-size: 4.5vw;
  font-weight: 600;
}

.branding span{
  font-size: 2vw;
  color: var(--light-yellow);
}

#main-menu{
  display: block;
}

#main-menu ul li{
  list-style-type: none;
}

.navigation{
  flex-basis: 50%;
}

.navigation ul{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.navigation ul li{
  margin-left: 30px;
}

.navigation ul li a,
.navigation ul li a:hover{
  color: var(--light-gray);
}

#mobile-menu{
  display: none;
}

.header .icon .svg-inline--fa{
  font-size: 30px;
}

.active{
  color: var(--light-blue) !important;
}

@media(min-width: 768px) and (max-width: 991px)  and (orientation: portrait){
  .header{
    height: 10%;
  }
}

@media(max-width: 767px) and (orientation: portrait){
  .header{
    height: 15%;
  }

  .branding h1{
    font-size: 10vw;
  }

  .branding span{
    font-size: 6vw;
  }
}

@media(max-width: 767px) and (orientation: landscape){
  .header{
    height: 20%;
  }

  .branding h1{
    font-size: 5vw;
  }

  .branding span{
    font-size: 3vw;
  }
}

@media(max-width: 767px){
  .branding-socials,
  .navigation{
    flex-basis: 100%;
  }

  #mobile-menu{
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  #main-menu{
    display: none;
    flex-basis: 100%;
    height: 0;
  }

  .navigation ul{
    justify-content: center;
    background-color: var(--dark-blue);
    z-index: 10;
    position: relative;
  }

  .navigation ul li {
    margin-left: 30px;
    margin: 0;
    width: 100%;
  }

  .navigation ul li a, .navigation ul li a:hover {
    color: var(--light-gray);
    width: 100%;
    display: block;
    text-align: center;
    padding: 15px 10px;
    box-sizing: border-box;
  }
}


/* Footer */

.footer{
  width: 100%;
  height: 6%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: center;
}

.socials a,
.socials a:hover{
  margin-right: 20px;
  margin-left: 20px;
  color: var(--light-gray);
}

.socials a .svg-inline--fa{
  font-size: 26px;
}

@media(min-width: 768px) and (max-width: 991px)  and (orientation: portrait){
  .footer{
    height: 10%;
  }
}

@media(max-width: 767px) and (orientation: portrait){
  .footer{
    height: 7%;
  }
}

@media(max-width: 767px) and (orientation: landscape){
  .footer{
    height: 10%;
  }
}


/* Main content area */

.content{
  height: calc(74% - 20px);
  margin-top: 20px;
  box-sizing: border-box;
}

.content > div{
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
}

.margin-bottom-p{
  margin-bottom: 50px !important;
}

p.strong.italic{
  color: var(--light-blue);
}

.details{
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}

@media(min-width: 768px) and (max-width: 991px)  and (orientation: portrait){
  .content{
    height: calc(80% - 20px);
  }
}

@media(max-width: 767px) and (orientation: portrait){
  .content{
    height: calc(78% - 20px);
  }
}

@media(max-width: 767px) and (orientation: landscape){
  .content{
    height: calc(70% - 20px);
  }
}


/* Home */

.welcome{
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}

.welcome h2,
.welcome p{
  flex-basis: 100%;
}

.welcome h2{
  font-size: 3vw;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--light-blue);
}

.welcome p{
  max-width: 33.33%;
}

@media(max-width: 767px) and (orientation: portrait){
  .welcome h2{
    font-size: 7vw;
  }
}

@media(max-width: 767px) and (orientation: landscape){
  .welcome h2{
    font-size: 5vw;
  }
}

@media(max-width: 767px){
  .welcome p{
    max-width: none;
  }
}


/* About */

.about h2{
  font-size: 3vw;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--light-blue);
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.about p{
  flex-basis: 100%;
  margin-bottom: 20px;
  text-align: center;
}

@media(max-width: 767px) and (orientation: portrait){
  .about h2{
    font-size: 7vw;
  }
}

@media(max-width: 767px) and (orientation: landscape){
  .about h2{
    font-size: 5vw;
  }
}


/* Work */

.work h2{
  font-size: 3vw;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--light-blue);
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media(max-width: 767px) and (orientation: portrait){
  .work h2{
    font-size: 7vw;
  }
}

@media(max-width: 767px) and (orientation: landscape){
  .work h2{
    font-size: 5vw;
  }
}

.work-item{
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
}

.work-item .year,
.work-item .company{
  display: inline-block;
}

.work-item .title{
  display: block;
}

.work-item .title{
  font-size: 2vw;
  font-weight: 600;
  color: var(--light-blue);
}

.work-item .title span{
  display: block;
  font-size: 0.8vw;
}

.work-item .title sup a,
.work-item .title sup a:hover{
  font-size: .8vw;
  color: var(--light-yellow);
  margin-left: 5px;
  margin-right: 5px;
}

.work-item .year,
.work-item .company{
  color: var(--light-gray);
}

.work-item .type,
.work-item .description,
.work-item .technologies{
  display: block;
  margin-bottom: 5px;
}

.work-item .type{
  color: var(--light-yellow);
}

.work-item .technologies span{
  margin: 10px 15px;
  padding: 5px 10px;
  background-color: var(--light-brown);
  color: var(--light-gray);
  font-size: 70%;
  border-radius: 50px;
  font-weight: 600;
  display: inline-block;
}

@media(max-width: 767px) and (orientation: landscape){
  .work-item .title{
    font-size: 4vw;
  }
  .work-item .title sup a,
  .work-item .title sup a:hover{
    font-size: 3.5vw;
  }
}

@media(max-width: 767px) and (orientation: portrait){
  .work-item .title{
    font-size: 6vw;
  }

  .work-item .title sup a,
  .work-item .title sup a:hover{
    font-size: 5.5vw;
  }
}

@media(max-width: 767px){
  .work-item .technologies span{
    margin: 10px 10px;
  }

  .work-item .title sup a,
  .work-item .title sup a:hover{
    margin-left: 10px;
    margin-right: 10px;
  }
}


/* Contact */

.contact-title{
  font-size: 3vw;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--light-blue);
}

.contact p{
  flex-basis: 100%;
  margin-bottom: 20px;
  text-align: center;
}

@media(max-width: 767px) and (orientation: landscape){
  .contact-title{
    font-size: 5vw;
  }
}

@media(max-width: 767px) and (orientation: portrait){
  .contact-title{
    font-size: 7vw;
  }
}


/* Services */

.services{
  display: flex;
}

.services h2{
  font-size: 3vw;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--light-blue);
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media(max-width: 767px) and (orientation: portrait){
  .services h2{
    font-size: 7vw;
  }
}

@media(max-width: 767px) and (orientation: landscape){
  .services h2{
    font-size: 5vw;
  }
}

.service-item{
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  justify-content: center;
}

.services h3{
  flex-basis: 100%;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

.services h3{
  font-size: 2vw;
  font-weight: 500;
  color: var(--light-gray);
}

.services ul{
  margin-bottom: 40px;
  display: block;
  text-align: center;
  list-style-type: none;
}

.services ul li{
  color: var(--light-yellow);
}

@media(max-width: 767px) and (orientation: landscape){
  .services h3{
    font-size: 4vw;
  }
}

@media(max-width: 767px) and (orientation: portrait){
  .services h3{
    font-size: 5.5vw;
  }
}